export default {
  getCertificados: state => {
    return state.certificados.map(c => {
      const certificado = JSON.parse(JSON.stringify(c));
      certificado.pruebas = certificado.pruebas.sort((a, b) => {
        if (a.indObligatoria == b.indObligatoria) {
          return ('' + a.glosa).localeCompare(b.glosa);
        } else if (a.indObligatoria == 'S' && b.indObligatoria == 'N') {
          return -1;
        } else {
          return 1;
        }
      });
      return certificado;
    }).sort((a, b) => {
      if (a.anio == b.anio) {
        return a.rendicion < b.rendicion ? -1 : 1;
      }
      return a.anio - b.anio;
    });
  },
  getCertificadoTable(state, getters) {
    const headers = [];
    const items = [];
    getters.getCertificados.forEach(certificado => {
      const header = [
        { text: 'NEM', value: 'nem' },
        { text: 'RANKING', value: 'rkg' },
      ];
      const item = {
        idCertificado: certificado.idCertificado,
        nem: certificado.puntajeNem || '-',
        rkg: certificado.puntajeRkg || '-',
        precio: certificado.tipoCertificado.valor,
      };
      certificado.pruebas.forEach(({glosa, puntaje}) => {
        header.push({
          text: glosa,
          value: glosa,
        });
        item[glosa] = puntaje;
      });
      header.push({
        text: 'PRECIO',
        value: 'precio',
        width: '20',
      }, {
        text: 'COMPRAR',
        value: 'actions',
        align: 'center',
        width: '20',
      });
      headers.push(header);
      items.push([item]);
    });
    return { headers, items };
  },
  getAniosSolicitud({ solicitudes, certificados, inicioSolicitud, terminoSolicitud }) {
    const aniosSolicitud = [];
    for (let index = terminoSolicitud; index >= inicioSolicitud; index--) {
      const tieneCertificado = certificados.some(({ anio }) => index == anio) && 'certificado disponible';
      const tieneSolicitud = solicitudes.some(({ anioCertificado }) => index == anioCertificado - 1) && 'año solicitado';
      const text = tieneCertificado || tieneSolicitud ? `${index} (${tieneCertificado || tieneSolicitud})`.trim() : index;
      aniosSolicitud.push({
        value: index,
        text,
        disabled: tieneCertificado || tieneSolicitud,
      });
      if (!certificados.some(({ anio }) => index == anio)) {
        aniosSolicitud.push(index);
      }
    }
    return aniosSolicitud;
  },
  getSolicitudes({ solicitudes }) {
    return solicitudes.map(solicitud => {
      return {
        ...solicitud,
        fechaIngreso: Date.parse(solicitud.fechaIngreso),
        anioRendicion: solicitud.anioCertificado - 1,
      }
    });
  }
};
